import '../styles/faq.scss';

import { Link } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

let getUniqueSectionData = ( data ) => {
	let uniqueSectionArray = [];
	let len = data.length;
	for ( let i = 0; i < len; i++ ) {
		if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
			uniqueSectionArray.push( data[i].node.data_category );
		}

	}
	return uniqueSectionArray;
};

let getformattedData2 = ( data, sectionsArray ) => {
	console.log( 'Inside fd2' );
	let output = [];
	let section;
	for ( let i = 0; i < sectionsArray.length; i++ ) {
		output['section_' + i] = {};
		section = data.filter( ( item ) => item.node.data_category == sectionsArray[i] && item.node );
		for ( let j = 0; j < section.length; j++ ) {
			output['section_' + i][j] = {
				que: section[j].node.attribute_2_key,
				section: section[j].node.attribute_1_value
			};
		}
	}
	console.log( 'going' );
	return output;
};

const getUniqueQuestions = ( data ) => {

	let uniqueQuestions = [];

	data.forEach( question => {
		let { data_category, style_category, attribute_2_key } = question.node;
		let question1 = { data_category, style_category, questionText: attribute_2_key };

		if ( style_category === 'faq_question' ) {
			uniqueQuestions.push( question1 );
		}
	} );

	return uniqueQuestions;
};

const FAQPage = () => {

	const data = useStaticQuery(
		graphql`
      query {
        allStrapiPageData(filter: {page_name: {in: ["faq_page", "faq_page_3"]}}) {
          edges {
            node {
              id
              page_name
              style_category
              content
              attribute_1_key
              attribute_1_value
              attribute_2_key
              attribute_2_value
              column
              data_category
              version
            }
        }
      }
    }`
	);

	const [ showResults, setShowResults ] = React.useState( {} );
	const [ showSections, setShowSections ] = React.useState( {} );
	const [ searchQuery, changeQuery ] = useState( '' );
	const [ matchedQuestions, setMatchedQuestions ] = useState( [] );
	const [ uniqueQuestions, setUniqueQuestions ] = useState( [] );

	let pageData = data.allStrapiPageData.edges;
	let uniqueSectionData = getUniqueSectionData( pageData );
	let visited = [];
	let sectionsLength = uniqueSectionData.length;

	for ( let i = 0; i < sectionsLength; i++ ) {
		visited[uniqueSectionData[i]] = false;
	}

	useEffect( () => {
		setUniqueQuestions( getUniqueQuestions( pageData ) );
	}, [data] );

	const onClick = ( event, index ) => {
		if ( !showResults[index] ) {
			setShowResults( { ...showResults, [index]: true } );
		} else {
			setShowResults( { ...showResults, [index]: false } );
		}
	};

	const onClick_section = ( event, index ) => {
		if ( !showSections[index] ) {
			setShowSections( { ...showSections, [index]: true } );
		} else {
			setShowSections( { ...showSections, [index]: false } );
		}
	};

	return (

		<Layout header={'menu'}>
			<SEO
				title="Get answers related to Life &amp; Home Insurance Queries "
				description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
				canonicalUrl='https://www.getmybubble.com/faq/'
				keywords="Home Insurance FAQ, Life Insurance FAQ, Insurance FAQ, Insurance Quotes FAQ"
				robots={ { 1:'index', 2: 'follow' } }
			/>

			<div id="faq" className="wrapper">
				<div className="flexColumn carrierCommonPage">
					<div className="company-info-container container-fluid">
						<div className="row">
							<div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<div>
									<h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                    Frequently Asked Questions
									</h1>

									{/* <div className="input-group">
                    <input className="form-control py-2 rounded-pill mr-1 pr-5 faq-ip-search" value={searchQuery} type="search"
                      onChange={queryFaqs} placeholder="Search for your question" />
                  </div> */}

									{
										matchedQuestions.map( ( question, i ) => <div key={i} style={{ height: '30px', backgroundColor: 'aliceblue', margin: '5px 10px' }}> {question.questionText} </div> )
									}
								</div>
							</div>
						</div>
					</div>

					<div className="container faq-margin-bottom common-container-space rentersfaq">
						<div className="row">
							<div className="col-lg-12">
								{data.allStrapiPageData.edges.map( ( s, section_index ) => {
									if ( visited[s.node.data_category] == false ) {
										visited[s.node.data_category] = true;
										return (
											<div key={section_index} className="row">
												<div className="col-lg-12">
													<div className='faqFlex' style={{ backgroundColor: 'white' }}>
														<div className='faqLeftContainer faq-left-container'>
															<div className="col-sm-12" onClick={function ( e ) {
																onClick_section( e, section_index );
															}} >
																<div className="align-items-center align-faq-que position-relative d-flex">
																	<div className="faq-section-headings">{s.node.attribute_1_value}</div>

																	<img src={showSections[section_index] ? UpArrow : DownArrow} className="faq-dd m-0" name={section_index} alt="Bubble Life Insurance" />
																</div>
															</div>

															<div className="col-12 both-space-padding">
																<div className="row">
																	{data.allStrapiPageData.edges.map( ( d, index ) => {
																		if ( showSections[section_index] && d.node.data_category == s.node.data_category ) {
																			return (
																				<div key={index} className="col-lg-6 col-12">
																					<div className={'faqSubContainer'}>
																						<div className={'faqFlex faq-container-align'}>
																							<div className='faqLeftContainer2 row'>
																								<div className="col-sm-12 col-12" onClick={function ( e ) {
																									onClick( e, index );
																								}} >
																									<div className="align-faq-que-box position-relative">
																										<div className="carrier-faq carrier-faq-width"> {d.node.attribute_2_key} </div>

																										<img src={showResults[index] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name={index} alt="Bubble Life Insurance" />
																									</div>

																									{showResults[index] ? <p
																										dangerouslySetInnerHTML={{ __html: d.node.content }} className="pt-lg-0 pt-2 carrier-faq faq-para-text"></p> : null}
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			);
																		}
																	} )}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										);
									}
								} )}
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">
								<div className="faqFlex" style={{ backgroundColor: 'white' }}>
									<div className="faqLeftContainer faq-left-container">
										<div className="col-sm-12"   onClick={function ( e ) {
											onClick_section( e, data.allStrapiPageData.edges.length );
										}} >
											<div className="align-items-center align-faq-que position-relative d-flex">
												<div className="faq-section-headings">HomePal<sup><b className="suptxt">TM</b></sup></div>

												<img src={showSections[data.allStrapiPageData.edges.length] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length} alt="Bubble Life Insurance" />

											</div>
										</div>

										<div className="col-12 both-space-padding">
											<div className='row'>
												{ showSections[ data.allStrapiPageData.edges.length ] ?
													<div className='col-12'>
														<div className="pt-lg-0 pt-2 carrier-faq faq-para-text">
															<p>Finding the right homeowner insurance can be confusing, but Bubble HomePal<sup>TM</sup> is here to help! It uses the power of AI to simplify the insurance shopping process and ensure you get the coverage you need.

																<br/>

                            Here's how Bubble HomePal<sup>TM</sup> makes finding home insurance easy:

																<br/>

																<ol className='lifepal-list'>
																	<li><strong>Understand your risks</strong>: Identifies potential threats to your home, its belongings and occupants, like fires, floods, or theft, based on your neighborhood.</li>

																	<li><strong>See what others experience</strong>: Provides insights into the most common and expensive insurance claims in your area.</li>

																	<li><strong>Find the perfect fit</strong>: Matches your home with recommended insurance policies and optional coverages (called riders or endorsements) that address your specific needs.</li>

																	<li><strong>Prevent future problems</strong>: Bubble HomePal

																		<sup>TM</sup> even suggests preventive safety measures to help you mitigate future damages from hazards and avoid claims to begin with (which helps keep your premiums low)!</li>
																</ol>

																<span className='homepallasttext'>With Bubble HomePal<sup><strong className='txtbold'>TM</strong></sup>, finding the right home insurance is a breeze.

																	<b className='go-there'>
																		<Link onClick={() => {
																			window.location.href = '/';
																		}} to="/"> Get started today!
																		</Link>
																	</b>
																</span>
															</p>
														</div>
													</div> : <></>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">
								<div className="faqFlex" style={{ backgroundColor: 'white' }}>
									<div className="faqLeftContainer faq-left-container">
										<div  onClick={function ( e ) {
											onClick_section( e, data.allStrapiPageData.edges.length + 1 );
										}} className="col-sm-12">
											<div className="align-items-center align-faq-que position-relative d-flex">
												<div className="faq-section-headings">LifePal<sup><b className="suptxt">TM</b></sup></div>

												<img src={showSections[data.allStrapiPageData.edges.length + 1] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 1} alt="Bubble Life Insurance" />
											</div>
										</div>

										<div className="col-12 both-space-padding">
											<div className='row'>
												{showSections[ data.allStrapiPageData.edges.length + 1] ?
													<div className='col-12'>
														<div className="pt-lg-0 pt-2 carrier-faq faq-para-text">
															<p>LifePal<sup>TM</sup>, embedded within Bubble’s life insurance shopping experience, is an AI-guided recommendation engine that makes finding the right life insurance fast, easy, and personalized.

																<br/>

                              Tired of sifting through endless life insurance options? Here’s how LifePal<sup>TM</sup> makes it simple:

																<br/>

																<ol className='lifepal-list'>
																	<li><strong>Tell us about yourself</strong>: Share your age, health, lifestyle, and goals. No need for lengthy forms - just quick, easy questions.</li>

																	<li><strong>We crunch the numbers</strong>: Our AI, trained on massive amounts of data, analyzes your info and anonymized data trends.</li>

																	<li><strong>Personalized picks</strong>: Forget generic options. LifePal™ curates policies from top insurers that perfectly match your needs and budget.</li>

																	<li><strong>Transparency matters</strong>: See clear comparisons of coverage amounts, terms, and features side-by-side.</li>

																	<li><strong>Expert guidance</strong> Get answers to your questions and insights into each policy to make an informed decision.</li>
																</ol>

                                Give Bubble’s LifePal<sup>TM</sup>-powered life insurance shopping a test drive. Get started today and enjoy peace of mind for tomorrow.
															</p>
														</div>
													</div>
													: <></>}
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>


						<div className="row">
							<div className="col-lg-12">
								<div className="faqFlex" style={{ backgroundColor: 'white' }}>
									<div className="faqLeftContainer faq-left-container">
										<div  onClick={function ( e ) {
											onClick_section( e, data.allStrapiPageData.edges.length + 3 );
										}} className="col-sm-12">
											<div className="align-items-center align-faq-que position-relative d-flex">
												<div className="faq-section-headings">Renters Insurance</div>

												<img src={showSections[data.allStrapiPageData.edges.length + 3] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 3} alt="Bubble Life Insurance" />
											</div>
										</div>

										<div className="col-12 both-space-padding">
											<div className='row'>
												{showSections[ data.allStrapiPageData.edges.length + 3] ?
													<div>
                            <p>Your rented space is your home, and protecting everything in your home is paramount. Get coverage for personal property, liability claims, and more.</p>
														{/* <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 100 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">What is Renters Insurance?</div>

																				<img src={showSections[100] ? UpArrow : DownArrow} className="faq-dd m-0" name={showSections[100]} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 100] ?
																				<div>
                                          <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">As a tenant, renters insurance protects your personal belongings in the rented space from loss or damage caused by disasters like fire, theft, burglary, and other events. It also offers additional living expenses in the event of damage caused to the home. Although not a legal requirement, a few landlords may ask for proof of renters insurance before renting their home out</p>
                                          <div className="table-responsive faqtbl">
                                            <table className="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Coverage Type</th>
                                                  <th scope="col">What’s covered</th>
                                                  <th scope="col">What’s not covered</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Personal Property</td>
                                                  <td>damage or loss to belongings due to covered perils like fire, theft, vandalism, windstorms, and certain water damage.</td>
                                                  <td>wear and tear, damage from pests or insects, and intentional damage by the policyholder.</td>
                                                </tr>
                                                <tr>
                                                  <td>Liability</td>
                                                  <td>When someone is injured or their property is damaged in your rental unit due to your legal liability. Covers medical expenses, legal fees, and property damage.</td>
                                                  <td>Intentional acts, injuries to yourself or family members, and business activities.</td>
                                                </tr>
                                                <tr>
                                                  <td>Additional Living Expenses</td>
                                                  <td>Living expenses if your rental becomes uninhabitable due to a covered event. Includes hotel stays, meals, and extra transportation expenses.</td>
                                                  <td>Permanent relocation costs or the cost of living beyond the policy limits.</td>
                                                </tr>
                                                <tr>
                                                  <td>Medical Payments</td>
                                                  <td>Medical expenses if someone is injured on the property</td>
                                                  <td>Injuries to the insured or household members</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <span className='italictext'>*Keep in mind that specific coverage can vary depending on the insurance provider and policy details, so it's essential to review your policy documents carefully</span>
                                        </div>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 101 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">What personal properties does Renters Insurance cover?</div>

																				<img src={showSections[ 101] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 101] ?
																				<div>
                                          <div class="table-responsive faqtbl">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Generally covered</th>
                                                  <th scope="col">Commonly excluded</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td><b>Household items:</b> Furniture, appliances, clothing, electronics, books, etc.</td>
                                                  <td>Flood damage</td>
                                                </tr>
                                                <tr>
                                                  <td><b>Electronics:</b> TVs, computers, laptops, tablets, etc.</td>
                                                  <td>Earthquake damage</td>
                                                </tr>
                                                <tr>
                                                  <td><b>Collectibles:</b> Art, antiques, jewelry, sports memorabilia, etc.</td>
                                                  <td>Mold</td>
                                                </tr>
                                                <tr>
                                                  <td><b>Equipment:</b> Bicycles, skis, golf clubs, musical instruments,</td>
                                                  <td>Beg bugs</td>
                                                </tr>
                                                <tr>
                                                  <td><b>Personal belongings:</b> Clothing, luggage, toiletries, etc.</td>
                                                  <td>War & terrorism</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 102 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">How much does renters insurance cost?</div>

																				<img src={showSections[ 102] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 102] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">The cost of renters insurance varies depending on several factors, including:
                                          <br/>

                                          <ol className='lifepal-list'>
                                            <li>Location</li>
                                            <li>Value of your belongings</li>
                                            <li>Deductible amount</li>
                                            <li>Coverage limits</li>
                                          </ol>

                                          However, renters insurance is generally quite affordable, often costing less than <b>$20</b> per month.
                                        </p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 103 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Why do I need renters insurance?</div>

																				<img src={showSections[ 103] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 103] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Renters insurance is a cost-effective way to safeguard yourself and your possessions while living in a rental. It not only shields you from financial loss in case of mishaps but also meets some landlords' lease requirements, ensuring protection for your belongings and potentially reducing liability risks for everyone.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 104 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Why do I need renters insurance?</div>

																				<img src={showSections[ 104] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 104] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">
                                          <ol className='lifepal-list'>
                                            <li>Evaluate your needs</li>
                                            <li>Answer a few easy questions on Bubble</li>
                                            <li>Get a renters insurance quote</li>
                                            <li>See if covers everything important to you</li>
                                            <li>Check if the premium fits your budget</li>
                                            <li>Choose a policy</li>
                                            <li>Get instantly approved</li>
                                          </ol>

                                          <b>Renters Insurance Nugget</b>
                                          <br/>
                                          An important aspect of renters insurance is understanding the policy's liability coverage. This coverage protects you financially if someone is injured on your rented property and you are found responsible. It can cover medical expenses, legal fees, and damages awarded in a lawsuit, offering crucial protection against unexpected liability claims.
                                        </p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div> */}

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 105 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">What is renters insurance?</div>

																				<img src={showSections[ 105] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 105] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Renters insurance is a type of insurance policy that provides coverage for your personal property and liability protection if you're renting a home or apartment.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 106 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">What does renters insurance typically cover?</div>

																				<img src={showSections[ 106] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 106] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Renters insurance typically covers your personal belongings (e.g., furniture, electronics, clothing) in case of theft, fire, vandalism, or other covered perils. It also provides liability coverage in case someone is injured while on your rented property and you're found responsible.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 107 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">What does renters insurance not cover?</div>

																				<img src={showSections[ 107] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 107] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Renters insurance usually doesn't cover damage caused by floods or earthquakes. Additional coverage may be required for these types of natural disasters. It also typically doesn't cover items of extremely high value, such as expensive jewelry or art, without additional riders.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 108 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">How do I determine how much coverage I need?</div>

																				<img src={showSections[ 108] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 108] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">To determine the amount of coverage you need, make an inventory of your belongings and estimate their value. You'll want enough coverage to replace your personal property in case of loss or damage.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 109 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Can I bundle renters insurance with other insurance policies?</div>

																				<img src={showSections[ 109] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 109] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Of course. Many insurance companies offer discounts for bundling renters insurance with other policies such as auto insurance.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 110 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">How do I file a claim?</div>

																				<img src={showSections[ 110] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 110] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">If you need to file a claim, contact your insurance company as soon as possible. They will guide you through the claims process, which typically involves providing documentation of the loss or damage.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 111 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">How often should I review my renters insurance policy?</div>

																				<img src={showSections[ 111] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 111] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">It's a good idea to review your renters insurance policy annually or whenever you experience major life changes (e.g., moving to a new apartment, acquiring expensive items) to ensure you have adequate coverage.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 112 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width"> How does renters insurance liability coverage work?</div>

																				<img src={showSections[ 112] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 112] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Renters insurance liability coverage protects you financially if you're found responsible for causing injury to someone or damaging their property. This coverage can help pay for medical bills, legal fees, and other expenses associated with a liability claim made against you.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 113 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Will renters insurance cover my personal belongings if they are stolen outside of my rented property?</div>

																				<img src={showSections[ 113] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 113] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Yes, renters insurance typically provides coverage for your personal belongings even if they are stolen outside of your rented property, such as from your car or while you're traveling. However, coverage limits and deductibles may apply, so it's important to review your policy.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 114 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Does renters insurance cover temporary living expenses if I need to relocate due to a covered loss?</div>

																				<img src={showSections[ 114] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 114] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Yes, many renters insurance policies include coverage for additional living expenses (ALE) if you're temporarily displaced from your rented home due to a covered loss, such as fire or severe damage.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 115 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Can I add coverage for high-value items like jewelry or collectibles to my renters insurance policy?</div>

																				<img src={showSections[ 115] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 115] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Yes, many renters insurance policies allow you to add additional coverage, known as endorsements or floaters, for high-value items that exceed the standard policy limits. This can provide extra protection for items like jewelry, fine art, or musical instruments.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

                            <div className="col-lg-12 col-12">
															<div className="faqSubContainer">
																<div className="faqFlex faq-container-align">
																	<div className="faqLeftContainer2 row" >
																		<div className="col-sm-12 col-12" onClick={function ( e ) {
																			onClick_section( e, 116 );
																		}} >
																			<div className="align-faq-que-box position-relative">
																				<div className="carrier-faq carrier-faq-width">Will renters insurance cover my belongings if I move to a new rental property?</div>

																				<img src={showSections[ 116] ? UpArrow : DownArrow} className="faq-dd m-0" name={data.allStrapiPageData.edges.length + 32} alt="Bubble Life Insurance" />
																			</div>

																			{showSections[ 116] ?
																				<p className="pt-lg-0 pt-2 carrier-faq faq-para-text">Yes, renters insurance generally provides coverage for your personal belongings regardless of where you're living, as long as the loss or damage occurs within the policy's coverage territory. However, you may need to update your policy with your new address and any changes in coverage needs.</p>
																				: <></>}
																		</div>
																	</div>
																</div>
															</div>
														</div>

													</div>
													: <></>}
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</Layout> );
};

export default FAQPage;
